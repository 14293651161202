import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Card, CardText, CardTitle, Button, Input } from 'reactstrap';
import Dialog from './components/dialog/editDialog';
import Dialogs from './components/dialog/loaderDialog';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { Redirect } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";
import { deleteFlowerData, patchKeraysData, putFlowersOrderData, patchValmiusProductsData, patchValmiusData, updateFlowersEdit, patchTarkastettuProductsData, postRullakko, putRullakkoToOrders, deleteRullakkoFromOrders, updateRullakkoData, postHylly, putHyllyToOrders, deleteHyllyFromOrders, updateHyllyData, getAllIdsToRemove } from './components/fetch/apiFetch';
import { socketConnChat, socketConnID, socketConnRullakko } from './components/socketio/socketio';
import { css } from "@emotion/core";
import MyAutosuggest from "./components/autoComplete/autoComplete";
import { FETCH_URL } from "./components/fetch/url";
import XLSX from 'xlsx';
import ErrorBoundary from './components/errorCatcher/ErrorBoundary';
import language from './components/language/language';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Loader from "react-spinners/ScaleLoader";

// Pictures
import logo from './pictures/Heimosen_Puutarha_logo.png';

//CSS files
import "./Styles/Table.css";
import "./Styles/progressBar.css";

let change = false;
var arr = [];
let delPrint2 = false;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const override2 = css`
  display: block;
  margin: 0 auto; 
  border-color: red;
`;

var img = new Image();
img.src = logo;

function changeData() {
  try {
    change = true;
  } catch (error) {
    console.log(error);
  };
}

function changeNormal() {
  try {
    change = false;
  } catch (error) {
    console.log(error);
  };
}

class PeopleCard extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      changedProp: "",
      sent: false,
      search: "",
      suggestions: [],
      printData: [],

      response: false,

      kauppa: '',
      customerInfo: '',
      ToimitusPVM: '',
      idArray: [],

      isOpen: false,
      isOpen2: false,

      location: '',
      date: sessionStorage.getItem('userDate'),
      startDate: new Date(),
      startDate2: new Date(),
      createdID: '',
      idf: [],
      addFlowersValue: 1,

      openWarning: false,
      valmisWarning: false,
      alreadyLoaded: false,
      rullakot: false,
      hyllyt: false,
      loading: false,
      loadingUpdt: false,
      loadingBtn: false,
      loadingRH: false,
      orderInfo: '',
      berries: false,
    }
  }

  Progress = ({ done, count, counter, id }) => {
    try {
      let data = change === false ? done = done ? done + "%" : "" : count === undefined ? "" : `${count}/${counter}`;
      if (data.includes('.')) {
        data = data.substring(0, data.indexOf(".")) + "%";
      }
      const [style, setStyle] = React.useState({});


      setTimeout(() => {
        const newStyle = {
          opacity: 1,
          width: `${done}`
        }
        setStyle(newStyle);
      }, 100);

      return (
        <div className="progress" onMouseEnter={() => changeData()} onMouseLeave={() => changeNormal()}>
          <div className={(((done === "100%" || count === counter) && id === "Kerätty") || (count === counter && id === "Kerätty") ? "progress-ready" : "progress-needed" && id === "Ei ole" ? "progress-cantbedone" : "progress-needed")} style={style}>
            <div className="dataFont">
              {data}
            </div>
          </div>
        </div>
      )
    } catch (error) {
      console.log(error);
    };
  }


  muokkaa(_id, products, kauppa, date, alisatieto, toimituspvm) {
    try {
      var dateS = date.split('/');
      var newDate = `${dateS[1]}/${dateS[0]}/${dateS[2]}`;

      var dateSp = toimituspvm.split('/');
      var newToimitusPVM = `${dateSp[1]}/${dateSp[0]}/${dateSp[2]}`;
      this.setState({
        startDate: new Date(newDate),
        startDate2: new Date(newToimitusPVM),
        isOpen2: true,
      })
    } catch (error) {
      console.log(error);
    };
  }

  warning() {
    try {
      this.setState({
        openWarning: true,
      })
    } catch (error) {
      console.log(error);
    };
  }

  valmis() {
    try {
      this.setState({
        valmisWarning: true
      })
    } catch (error) {
      console.log(error);
    };
  }

  async valmisData(_id, products, counts2) {
    try {
      this.setState({
        loadingBtn: true
      });
      let ids = [];
      let valmius;
      let valmius2;
      let location = "";
      let i = 0;
      let p = 0;
      let id;

      if (products.length === counts2.Arkisto && sessionStorage.getItem('siteName') === "Valmiit") {
        valmius = "Arkistoitu";
        valmius2 = "Arkistoitu";
        switch (localStorage.getItem("userLocation")) {
          case "Tuusjärvi":
            location = "tuusjarvi";
            patchValmiusData(valmius2, _id, location);
            break;

          case "Ryönä":
            location = "ryona";
            patchValmiusData(valmius2, _id, location);
            break;

          case "Molemmat":
            while (p < 2) {
              if (p === 0) {
                location = "ryona";
                patchValmiusData(valmius2, _id, location);
                p++;
              } else if (p === 1) {
                location = "tuusjarvi";
                patchValmiusData(valmius2, _id, location);
                p++;
              }
            }
            break;
          default:
            // ...
            break;
        }
        ids = await products.map(product => {
          return product._id
        })

        i = 0;
        while (i < ids.length) {
          id = ids.shift();
          await patchValmiusProductsData(id, valmius)
        }
        i++;
      } else {
        valmius = "Kerätty";
        valmius2 = "Kyllä";
        switch (localStorage.getItem("userLocation")) {
          case "Tuusjärvi":
            location = "tuusjarvi";
            patchValmiusData(valmius2, _id, location);
            break;

          case "Ryönä":
            location = "ryona";
            patchValmiusData(valmius2, _id, location);
            break;

          case "Molemmat":
            while (p < 2) {
              if (p === 0) {
                location = "ryona";
                patchValmiusData(valmius2, _id, location);
                p++;
              } else if (p === 1) {
                location = "tuusjarvi";
                patchValmiusData(valmius2, _id, location);
                p++;
              }
            }
            break;
          default:
            // ...
            break;
        }
        ids = await products.map(product => {
          return product._id
        })

        i = 0;
        while (i < ids.length) {
          id = ids.shift();
          await patchValmiusProductsData(id, valmius)
        }
        i++;
      }
      this.setState({
        valmisWarning: false
      })
      socketConnChat();
    } catch (err) {
      console.log(err);
    };
  }

  async addFlowers(_id) {
    try {
      this.setState({
        loadingUpdt: true
      });
      if (!this.state.alreadyLoaded) {
        let val = await getAllIdsToRemove(_id);
        this.setState({
          idArray: val.products
        })
      }

      let i = 0;
      while (i < this.state.addFlowersValue) {
        let keraysData = localStorage.getItem("userLocation");
        if (localStorage.getItem("userLocation") === "Molemmat") {
          keraysData = "Ryönä";
        }

        await fetch(FETCH_URL + 'products/post', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('userData')
          },
          body: JSON.stringify({
            kerays: keraysData
          }),
        })
          .then(response => response.json())
          .then(json => {
            console.log(json);

            let jsonID = json.createdProduct._id;
            this.state.idArray.push(jsonID)

            this.setState({
              idArray: this.state.idArray.toString().split(",")
            })
          })
          .catch((error) => {
            console.log(error);
          });
        i++;
      }
      this.setState({
        alreadyLoaded: true
      })
      this.addToIDS(_id);
    } catch (error) {
      console.log(error);
    };
  }

  async addToIDS(_id) {
    try {
      var filteredProducts = this.state.idArray.filter(Boolean);
      fetch(FETCH_URL + 'orders/put/id/' + _id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('userData')
        },
        body: JSON.stringify({
          products: filteredProducts
        }),
      })
        .then(response => response.json())
        .then(json => {
          console.log(json);
        })
        .catch((error) => {
          console.log(error);
        });
      socketConnChat();
      this.setState({
        loadingUpdt: false
      });
    } catch (error) {
      console.log(error);
    };
  }

  async patchData(product, kauppa, _id, date) {
    try {
      this.setState({
        loading: true
      });
      this.printOut(product, kauppa, date);
      const idvalues = document.getElementById(`keratty/${product._id}`).value;
      var maara = document.getElementById(product._id).value;
      await patchKeraysData(product, idvalues, maara);
      socketConnID(_id);
      this.setState({
        loading: false
      });
    } catch (error) {
      console.log(error);
    };
  }

  async putFlowersIData(products, _id, kauppa, alisatieto, toimituspvm, date, orderLisatieto) {
    try {
      this.setState({
        loadingUpdt: false
      });
      let ids = await products.map(product => {
        return product._id
      })

      let i = 0;
      while (i < ids.length) {
        let id = ids.shift();
        var kukka = document.getElementById(`kukka/${id}`).value;
        var toimi = document.getElementById(`toimi/${id}`).value;
        var kerays = document.getElementById(`kerays/${id}`).value;
        var lisatieto = document.getElementById(`lisatieto/${id}`).value;

        await updateFlowersEdit(products, id, kukka, toimi, kerays, lisatieto);
      }
      this.putOrderData(_id, kauppa, alisatieto, toimituspvm, date, orderLisatieto)
      socketConnChat();
    } catch (error) {
      console.log(error);
    };
  }

  async putOrderData(_id, kauppa, alisatieto, toimituspvm, date, orderLisatieto) {
    try {
      var asiakas = document.getElementById(`kauppa/${_id}`).value;
      var asiakaslisatieto = this.state.customerInfo;
      var toimitusaika = this.state.ToimitusPVM;
      var keraysPVM = format(this.state.startDate, "dd/MM/yyyy");
      var orderLisatieto2 = this.state.orderInfo;

      await putFlowersOrderData(asiakas, asiakaslisatieto, toimitusaika, kauppa, alisatieto, toimituspvm, _id, keraysPVM, date, orderLisatieto2, orderLisatieto);
      socketConnChat();
      this.setState({
        isOpen2: false,
        loading: false
      })
    } catch (error) {
      console.log(error);
    };
  }

  async deleteData(product, _id) {
    try {
      this.setState({
        loadingUpdt: true
      });
      document.getElementById(`kukka/${product._id}`).value = null;
      document.getElementById(`toimi/${product._id}`).value = null;
      document.getElementById(`lisatieto/${product._id}`).value = null;
      await deleteFlowerData(product);
      socketConnID(_id);
      this.setState({
        loadingUpdt: false
      });
    } catch (error) {
      console.log(error);
    };
  }

  handleChange = (event) => {
    try {
      this.setState({
        [event.target.name]: event.target.value
      });
    } catch (error) {
      console.log(error);
    };
  }

  pvmMuutos = (date) => {
    try {
      this.setState({
        startDate: date
      });
    } catch (error) {
      console.log(error);
    };
  }

  toimituspvmMuutos = (date) => {
    try {
      this.setState({
        startDate2: date,
        ToimitusPVM: format(date, 'dd/MM/yyyy')
      });
    } catch (error) {
      console.log(error);
    };
  }

  jsonToExcel(products, _id) {
    try {

      var fileName = "EXCEL";
      var workSheet = XLSX.utils.json_to_sheet(products);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, workSheet, fileName);
      XLSX.writeFile(wb, _id + ".xlsx");
    } catch (error) {
      console.log(error);
    };
  }

  printOut(product, kauppa, date) {
    if (this.props.delPrint) {
      arr = [];
      delPrint2 = false;
      this.props.cleanUp(delPrint2);
    }
    if (product.keratty === "Odottaa keräystä") {
      arr.push({
        kauppa: kauppa,
        date: date,
        tuote: product.kukka,
        maara: product.toimi,
        lisatieto: product.lisatieto,
        id: product._id,
        kerays: product.kerays
      });

      this.setState({
        printData: arr
      })
      this.props.printDataArr(arr)
    }
  }

  patchTarkastettu(product, _id) {
    let valmius;
    switch (product.tarkastettu) {
      case "Ei":
        valmius = "Kyllä";
        break;

      case "Kyllä":
        valmius = "Arkisto"
        break;

      case "Arkisto":
        valmius = "Ei";
        break;

      default:
        valmius = "Ei";
        break;
    }
    patchTarkastettuProductsData(product, valmius);
    socketConnID(_id);
  }

  htmlToPDF(_id, products, kauppa, date, alisatieto, toimituspvm) {
    try {
      var doc = new jsPDF();
      let i = 0;
      let b = 1;
      let productsLenght = products.length;
      let result = [];
      let berry = this.state.berries;

      if (sessionStorage.getItem('siteName') !== "Kerättävät") {
        for (i = 0; i < productsLenght; i++) {
          result.push([products[i].kukka.toString(), products[i].kerattymaara.toString()]);
        };
      } else {
        for (i = 0; i < productsLenght; i++) {
          result.push([products[i].kukka.toString(), products[i].kerays.toString(), products[i].toimi.toString()]);
        };
      }

      let text = alisatieto.toString();
      var textLine = doc.splitTextToSize(text, 75);
      let toimituspaiva = toimituspvm.toString();
      let arvTPVM = doc.splitTextToSize(`Toimituspäivämäärä: ${toimituspaiva}`, 300);
      let headHeader = ['Tuote', 'Kerättymäärä'];
      if (sessionStorage.getItem('siteName') === "Kerättävät") {
        headHeader = ['Tuote', 'Keräyspaikka', 'Tilattumäärä'];
        arvTPVM = doc.splitTextToSize(`Arvioitu toimituspäivämäärä: ${toimituspaiva}`, 300);
      }

      var header = function () {
        doc.setFontSize(15)
        doc.text(`Keräyspäivämäärä: ${date.toString()}`, 15, 32)
        doc.text(arvTPVM, 15, 39)
        doc.text(`${kauppa.toString()}`, 15, 50)
        doc.addImage(img, "png", 15, 2, 50, 22)
        doc.setFontSize(12)
        if (berry) {
          doc.text('Säilytys lämpötila +2 - +8', 150, 39)
        }
        doc.setFontSize(10)
        doc.text('EU-KASVIPASSI / EU-laatu', 150, 50)
        doc.text('Plant passport FI-Evira 56466', 150, 55)
        doc.setFontSize(10)
        doc.text(textLine, 150, 30)
        doc.text(`${_id.toString()}`, 15, 58)
        doc.text(`${b.toString()}`, 202, 7)
        b++;
      };

      doc.autoTable({
        head: [headHeader],
        body: result,
        margin: { horizontal: 0, top: 60 },
        bodyStyles: { valign: 'top' },
        styles: { overflow: 'linebreak', cellWidth: 'wrap' },
        columnStyles: { text: { cellWidth: 'auto' } },
        didDrawPage: header
      });

      doc.save('order.pdf');
    } catch (err) {
      console.log(err);
    };
  }

  async addRullakko(kauppa, _id, rullakot) {
    try {
      this.setState({
        loadingRH: true
      })
      let getRIDS = [];
      let rVuosi = format(new Date(), "yyyy");
      getRIDS = rullakot.map(rullakko => {
        return rullakko._id;
      });
      let rullakkoID = await postRullakko(kauppa, rVuosi);
      let newID = rullakkoID.createdRullakko.id;
      let rullakkoIDS = getRIDS.concat(newID);
      await putRullakkoToOrders(_id, rullakkoIDS);
      socketConnID(_id);
      socketConnRullakko();
      this.setState({
        loadingRH: false
      })
    } catch (err) {
      console.log(err);
    };
  }

  async addHylly(kauppa, _id, hyllyt) {
    try {
      this.setState({
        loadingRH: true
      })
      let getRIDS = [];
      let rVuosi = format(new Date(), "yyyy");
      getRIDS = hyllyt.map(hylly => {
        return hylly._id;
      });
      let hyllyID = await postHylly(kauppa, rVuosi);
      let newID = hyllyID.createdHylly._id;
      let hyllyIDS = getRIDS.concat(newID);
      await putHyllyToOrders(_id, hyllyIDS);
      socketConnID(_id);
      socketConnRullakko();
      this.setState({
        loadingRH: false
      })
    } catch (err) {
      console.log(err);
    };
  }

  async deleteRullakko(rullakko, _id) {
    try {
      this.setState({
        loadingRH: true
      })
      document.getElementById(`rNimi/${rullakko._id}`).value = null;
      document.getElementById(`rMaara/${rullakko._id}`).value = null;
      await deleteRullakkoFromOrders(rullakko);
      socketConnID(_id);
      socketConnRullakko();
      this.setState({
        loadingRH: false
      })
    } catch (err) {
      console.log(err);
    };
  }

  async deleteHylly(hylly, _id) {
    try {
      this.setState({
        loadingRH: true
      })
      document.getElementById(`rHylly/${hylly._id}`).value = null;
      document.getElementById(`rHyllyjenMaara/${hylly._id}`).value = null;
      await deleteHyllyFromOrders(hylly);
      socketConnID(_id);
      socketConnRullakko();
      this.setState({
        loadingRH: false
      })
    } catch (err) {
      console.log(err);
    };
  }

  async updateRullakko(rullakko, _id, kauppa) {
    try {
      this.setState({
        loadingRH: true
      })
      let rNimi = document.getElementById(`rNimi/${rullakko._id}`).value;
      let rMaara = document.getElementById(`rMaara/${rullakko._id}`).value;
      await updateRullakkoData(rullakko, kauppa, rNimi, rMaara);

      socketConnID(_id);
      socketConnRullakko();
      this.setState({
        loadingRH: false
      })
    } catch (err) {
      console.log(err);
    };
  }

  async updateHylly(hylly, kauppa, _id) {
    try {
      this.setState({
        loadingRH: true
      })
      let rHylly = document.getElementById(`rHylly/${hylly._id}`).value;
      let rHyllyjenMaara = document.getElementById(`rHyllyjenMaara/${hylly._id}`).value;
      await updateHyllyData(hylly, kauppa, rHylly, rHyllyjenMaara);

      socketConnID(_id);
      socketConnRullakko();
      this.setState({
        loadingRH: false
      })
    } catch (err) {
      console.log(err);
    };
  }

  componentDidMount() {
    try {
      this.props.person.products.map((productItem) => {
        if (productItem.kukka.toLowerCase().includes("vadelma")) {
          this.setState({
            berries: true
          })
        }
      })
      this._isMounted = true;
      var result = this.state.startDate2;
      result.setDate(result.getDate() + 1);
      this.setState({
        startDate2: result,
        location: localStorage.getItem("userLocation"),
        changedProp: this.props.search
      });

      if (this.state.date) {
      } else {
        this.setState({
          date: this.state.startDate
        })
        sessionStorage.setItem("userDate", format(new Date(), 'dd/MM/yyyy'));
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    };
  }

  render() {
    let { tuusjarvi, ryona, _id, products, kauppa, date, alisatieto, toimituspvm, rullakot, hyllyt, orderLisatieto } = this.props.person;
    let { loading, loadingUpdt, loadingBtn, loadingRH } = this.state;

    if (sessionStorage.getItem("userData") === null) {
      return <Redirect to="/" />
    }

    // Counter for displaying procentages.
    let array = [];
    let result = {};
    let counts = {};
    array.push(
      products.map(doc => {
        return doc.keratty;
      })
    )
    Object.keys(result).map(key => ({ [key]: result[key] }))
    for (let i = 0; i < array.length; i++) {
      result[array[i]] = (result[array[i]] || 0) + 1
    }
    Object.keys(result).map(str => str.replace(/\s/g, '')).toString().split(",").forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

    // Counter2 for displaying button in valmiit.
    let array2 = [];
    let result2 = {};
    let counts2 = {};
    array2.push(
      products.map(doc => {
        return doc.tarkastettu;
      })
    )
    Object.keys(result2).map(key => ({ [key]: result2[key] }))
    for (let i = 0; i < array.length; i++) {
      result2[array2[i]] = (result2[array2[i]] || 0) + 1
    }
    Object.keys(result2).map(str => str.replace(/\s/g, '')).toString().split(",").forEach(function (x) { counts2[x] = (counts2[x] || 0) + 1; });

    return (
      <ErrorBoundary>
        <div className="myDiv">
          <div className="NavBlock"></div>
          <div>
            <Card className="Cards">
              <CardTitle>{language[localStorage.getItem('language')].ready5} {date}</CardTitle>
              {sessionStorage.getItem("userValmis") === "Kerätty" ? <CardTitle>{language[localStorage.getItem('language')].ready6} {toimituspvm}</CardTitle> : sessionStorage.getItem("userValmis") === "Arkistoitu" ? <CardTitle>{language[localStorage.getItem('language')].ready6} {toimituspvm}</CardTitle> : undefined}
              <CardTitle>{kauppa}</CardTitle>
              <CardText>{_id}</CardText>
              <CardText className="lisatieto">{alisatieto}</CardText>
              <CardText className="orderLisatietoTable">{orderLisatieto}</CardText>
              <CardText></CardText>
              {sessionStorage.getItem("userValmis") === "Kerätty" || sessionStorage.getItem("userValmis") === "Arkistoitu" ? <CardText>{language[localStorage.getItem('language')].tarkastettuR}{ryona === "Kyllä" ? language[localStorage.getItem('language')].tarkastettuAnswerYes : ryona === "Arkistoitu" ? "Arkistoitu" : language[localStorage.getItem('language')].tarkastettuAnswerNo}</CardText> : undefined}
              {sessionStorage.getItem("userValmis") === "Kerätty" || sessionStorage.getItem("userValmis") === "Arkistoitu" ? <CardText>{language[localStorage.getItem('language')].tarkastettuT}{tuusjarvi === "Kyllä" ? language[localStorage.getItem('language')].tarkastettuAnswerYes : tuusjarvi === "Arkistoitu" ? "Arkistoitu" : language[localStorage.getItem('language')].tarkastettuAnswerNo}</CardText> : undefined}

              {sessionStorage.getItem("userValmis") === "Kerätty" || sessionStorage.getItem("userValmis") === "Arkistoitu" ? <Button className="rullakot" onClick={() => this.setState({ rullakot: true })}>{language[localStorage.getItem('language')].trolleys}</Button> : undefined}
              {sessionStorage.getItem("userValmis") === "Kerätty" || sessionStorage.getItem("userValmis") === "Arkistoitu" ? <Button className="hyllyt" onClick={() => this.setState({ hyllyt: true })}>{language[localStorage.getItem('language')].shelves}</Button> : undefined}

              <Dialog className="DelWarn" isOpen2={this.state.rullakot} onLoad={loadingRH} onClose={(e) => this.setState({ rullakot: false })}>
                {rullakot.map(rullakko =>
                  <div className="bottomRulla">
                    {
                      loadingRH ?
                        <div className="tableLoaders">
                          <Loader
                            css={override}
                            height={140}
                            width={16}
                            color={"#123abc"}
                            loading={loadingRH} />
                        </div>
                        : undefined
                    }
                    <Card className="rullakkoKortti">
                      <CardText className="rullakkoHolder">{language[localStorage.getItem('language')].trolleyK}</CardText>
                      <Input id={`rNimi/${rullakko._id}`} defaultValue={rullakko.rullakonNimi} type="select" className="rullakkoNimi">
                        <option>Oma</option>
                        <option>Piraatti</option>
                        <option>TAK5</option>
                      </Input>
                      <CardText className="rullakkoHolder">{language[localStorage.getItem('language')].trolleyKA}</CardText>
                      <Input id={`rMaara/${rullakko._id}`} className="rullakkoNimi" placeholder={rullakko.rullakoidenMaara}></Input>

                      <Button color="primary" onClick={() => this.updateRullakko(rullakko, _id, kauppa)}>{language[localStorage.getItem('language')].trolleySave}</Button>
                      <Button onClick={() => this.deleteRullakko(rullakko, _id)}>{language[localStorage.getItem('language')].poista}</Button>
                    </Card>
                  </div>
                )}
                <Button className="addRullakko" onClick={() => this.addRullakko(kauppa, _id, rullakot)}>+</Button>
              </Dialog>

              <Dialogs isOpen={loadingBtn}>
                <div className="Spinner">
                  <Loader
                    css={override2}
                    height={140}
                    width={16}
                    color={"#123abc"}
                    loading={loadingBtn}
                  />
                </div>
              </Dialogs>

              <Dialog className="DelWarn" isOpen2={this.state.hyllyt} onLoad={loadingRH} onClose={(e) => this.setState({ hyllyt: false })}>
                {hyllyt.map(hylly =>
                  <div className="bottomRulla">
                    {
                      loadingRH ?
                        <div className="tableLoaders">
                          <Loader
                            css={override}
                            height={140}
                            width={16}
                            color={"#123abc"}
                            loading={loadingRH} />
                        </div>
                        : undefined
                    }
                    <Card className="rullakkoKortti">
                      <CardText className="rullakkoHolder">{language[localStorage.getItem('language')].shelfK}</CardText>
                      <Input id={`rHylly/${hylly._id}`} defaultValue={hylly.hyllynNimi} type="select" className="rullakkoNimi">
                        <option>Oma</option>
                        <option>Vaihto</option>
                      </Input>
                      <CardText className="rullakkoHolder">{language[localStorage.getItem('language')].shelfKA}</CardText>
                      <Input id={`rHyllyjenMaara/${hylly._id}`} className="rullakkoNimi" placeholder={hylly.hyllyjenMaara}></Input>
                      <Button color="primary" onClick={() => this.updateHylly(hylly, kauppa, _id)}>{language[localStorage.getItem('language')].trolleySave}</Button>
                      <Button onClick={() => this.deleteHylly(hylly, _id)}>{language[localStorage.getItem('language')].poista}</Button>
                    </Card>
                  </div>
                )}
                <Button className="addRullakko" onClick={() => this.addHylly(kauppa, _id, hyllyt)}>+</Button>
              </Dialog>

              <div className="loaders" >
                <div className="loaderMargins">
                  <CardText className="hover">{language[localStorage.getItem('language')].statusBar1}</CardText>
                  <this.Progress done={100 * Math.abs(counts.Odottaakeräystä / Object.keys(result).toString().split(",").length)} count={counts.Odottaakeräystä} counter={products.length} id={"Odottaa keräystä"} />
                </div>

                <div className="loaderMargins">
                  <CardText className="hover">{language[localStorage.getItem('language')].statusBar2}</CardText>
                  <this.Progress done={100 * Math.abs(counts.Keräyksessä / Object.keys(result).toString().split(",").length)} count={counts.Keräyksessä} counter={products.length} id={"Keräyksessä"} />
                </div>

                <div className="loaderMargins">
                  <CardText className="hover">{language[localStorage.getItem('language')].statusBar3}</CardText>
                  <this.Progress done={100 * Math.abs(counts.Kerätty / Object.keys(result).toString().split(",").length)} count={counts.Kerätty} counter={products.length} id={"Kerätty"} />
                </div>

                <div className="loaderMargins">
                  <CardText className="hover">{language[localStorage.getItem('language')].statusBar4}</CardText>
                  <this.Progress done={100 * Math.abs(counts.Eiole / Object.keys(result).toString().split(",").length)} count={counts.Eiole} counter={products.length} id={"Ei ole"} />
                </div>
              </div>

              <Table className="Tables" id={`id/${_id}`}>

                <Thead>
                  <Tr>
                    <Th>{language[localStorage.getItem('language')].tuote}</Th>
                    <Th>{language[localStorage.getItem('language')].kerataan}</Th>
                    <Th>{language[localStorage.getItem('language')].kerayspiste}</Th>
                    <Th>{language[localStorage.getItem('language')].lisatietoa}</Th>
                    <Th>{language[localStorage.getItem('language')].keraamassa}</Th>
                    <Th>{language[localStorage.getItem('language')].kerattymaara}</Th>
                  </Tr>
                </Thead>

                {
                  loading ?
                    <div className="tableLoaders">
                      <Loader
                        css={override}
                        height={140}
                        width={16}
                        color={"#123abc"}
                        loading={loading} />
                    </div>
                    : undefined
                }

                {products.map(product => {
                  return (
                    <Tbody key={product._id}>
                      <Tr className={product.tarkastettu === "Ei" ? undefined : product.tarkastettu === "Arkisto" ? "ArkistoRow" : "ValmisRow"} onDoubleClick={sessionStorage.getItem('btnName') === "Valmiit" ? () => this.patchTarkastettu(product, _id) : undefined}>
                        <Td className="KukkaTable">{product.kukka}</Td>
                        <Td>{product.toimi}</Td>
                        <Td>{product.kerays}</Td>
                        <Td className="lisatietoTable">{product.lisatieto}</Td>
                        <Td>
                          <Input className={product.keratty === "Odottaa keräystä" ? "keraamassaBtn" : product.keratty === "Keräyksessä" ? "kerayksessaBtn" : product.keratty === "Kerätty" ? "kerattyBtn" : product.keratty === "Ei ole" ? "eioleBtn" : "keraamassaBtn"}
                            type="button"
                            id={`keratty/${product._id}`}
                            value={localStorage.getItem('language') === "1" ? product.keratty === "Odottaa keräystä" ? language[1].statusBar1 : product.keratty === "Keräyksessä" ? language[1].statusBar2 : product.keratty === "Kerätty" ? language[1].statusBar3 : product.keratty === "Ei ole" ? language[1].statusBar4 : product.keratty : product.keratty}
                            placeholder={product.keratty}
                            onClick={() => this.patchData(product, kauppa, _id, date)}>
                          </Input>
                        </Td>
                        <Td>
                          <Input className="kerattyMaara"
                            type="number"
                            id={product._id}
                            placeholder={product.kerattymaara}
                            name="kerattymaara">
                          </Input>
                        </Td>
                      </Tr>
                    </Tbody>
                  )
                })}
              </Table>
              {sessionStorage.getItem('userRole') === "Admin" ?
                <Button
                  name="valmisBtn"
                  className="valmisBtn"
                  disabled={sessionStorage.getItem('userRole') === "Admin" ? sessionStorage.getItem('siteName') === "Valmiit" ? products.length === counts2.Arkisto ? false : true : false : true}
                  color="success"
                  onClick={() => this.valmis()}>
                  {sessionStorage.getItem("siteName") === "Valmiit" ? language[localStorage.getItem('language')].arkistoi : language[localStorage.getItem('language')].valmis}
                </Button>
                : undefined}

              {sessionStorage.getItem('userRole') === "Admin" ? <Button name="muokkaaBtn" className="muokkaaBtn" disabled={sessionStorage.getItem('userRole') === "Admin" ? false : true} color="primary" onClick={() => this.muokkaa(_id, products, kauppa, date, alisatieto, toimituspvm)}>{language[localStorage.getItem('language')].muokkaa}</Button> : undefined}
              {sessionStorage.getItem('userRole') === "Admin" ? <Button name="poistaBtn" className="poistaBtn" disabled={sessionStorage.getItem('userRole') === "Admin" ? false : true} color="danger" onClick={() => this.warning()}>{language[localStorage.getItem('language')].poista}</Button> : undefined}
              {sessionStorage.getItem('userRole') === "Admin" ? <Button name="vieExcel" className="vieExcel" disabled={sessionStorage.getItem('userRole') === "Admin" ? false : true} color="info" onClick={() => this.jsonToExcel(products, _id)}>{language[localStorage.getItem('language')].vieExceliin}</Button> : undefined}
              {sessionStorage.getItem('userRole') === "Admin" ? <Button name="vieExcel" className="vieExcel" disabled={sessionStorage.getItem('userRole') === "Admin" ? false : true} color="warning" onClick={() => this.htmlToPDF(_id, products, kauppa, date, alisatieto, toimituspvm)}>{language[localStorage.getItem('language')].talPDF}</Button> : undefined}

            </Card>

            <Dialog className="DelWarn" isOpen2={this.state.openWarning} onClose={(e) => this.setState({ openWarning: false })}>

              <Card className="Cards">

                <CardTitle className="warningBox">{language[localStorage.getItem('language')].delete1}</CardTitle>
                <CardText className="warningBox">HUOM. Tämä poistaa myös toisen toimipaikan keräyslistan mikäli ne ovat tehty samaan tauluun!</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready3 + _id}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready4 + kauppa}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready5 + date}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready6 + toimituspvm}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].delete2 + products.length}</CardText>

                <Button name="delete_kylla" className="dialogBtn" color="success" onClick={() => this.props.removePerson(_id, products, rullakot, hyllyt) + this.setState({ openWarning: false, loadingBtn: true })}>{language[localStorage.getItem('language')].yes}</Button>
                <Button name="delete_ei" className="dialogBtn" color="danger" onClick={() => this.setState({ openWarning: false })}>{language[localStorage.getItem('language')].no}</Button>

              </Card>
            </Dialog>


            <Dialog className="DelWarn" isOpen2={this.state.valmisWarning} onClose={(e) => this.setState({ valmisWarning: false })}>

              <Card className="Cards">

                {sessionStorage.getItem("userValmis") === "Ei" ? <CardTitle className="warningBox">{language[localStorage.getItem('language')].ready1}</CardTitle> : undefined}
                {sessionStorage.getItem("userValmis") === "Kerätty" ? <CardTitle className="warningBox">{language[localStorage.getItem('language')].ready11}</CardTitle> : undefined}
                {sessionStorage.getItem("userValmis") === "Ei" ? <CardTitle className="warningBox">{language[localStorage.getItem('language')].ready2}</CardTitle> : undefined}
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready3 + _id}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready4 + kauppa}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready5 + date}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready6 + toimituspvm}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready7 + products.length}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready8} {counts.Kerätty === undefined ? 0 : counts.Kerätty}/{products.length}</CardText>
                <CardText className="warningBox">{language[localStorage.getItem('language')].ready9} {counts.Eiole === undefined ? 0 : counts.Eiole}/{products.length}</CardText>

                <Button name="valmis_kylla" className="dialogBtn" color="success" onClick={() => this.valmisData(_id, products, counts2)}>{language[localStorage.getItem('language')].yes}</Button>
                <Button name="valmis_ei" className="dialogBtn" color="danger" onClick={() => this.setState({ valmisWarning: false })}>{language[localStorage.getItem('language')].no}</Button>

              </Card>
            </Dialog>

            <Dialog className="Muokkaus" isOpen2={this.state.isOpen2} onLoad={loadingUpdt} onClose={(e) => this.setState({ isOpen2: false })}>
              <Card className="UpdateCards">
                <div>
                  <CardTitle className="KeraysPVM">{language[localStorage.getItem('language')].kerayspvm}</CardTitle>
                  <DatePicker className="DateUpdate"
                    selected={this.state.startDate}
                    onChange={this.pvmMuutos}
                    dateFormat="d/MM/yyyy"
                    onCalendarClose={() => sessionStorage.setItem('userDate2', format(this.state.startDate, "dd/MM/yyyy"))}
                  />

                  <CardTitle className="ToimitusPVMText">{language[localStorage.getItem('language')].toimituspvm}</CardTitle>
                  <DatePicker className="ToimitusPVM"
                    selected={this.state.startDate2}
                    onChange={this.toimituspvmMuutos}
                    dateFormat="dd/MM/yyyy"
                  />

                  <Input
                    className="CustomerInfo"
                    type="textarea"
                    name="customerInfo"
                    placeholder={alisatieto}
                    onChange={this.handleChange}>
                  </Input>

                  <Input
                    className="CustomerOrderInfo"
                    name="orderInfo"
                    placeholder={orderLisatieto}
                    onChange={this.handleChange}>
                  </Input>
                </div>

                <CardTitle>
                  <MyAutosuggest items={this.props.items2} id={`kauppa/${_id}`} placeholder={kauppa} sendClass={"AutoCompletePropsInput"} getDivClass={"AutoCompletePropsText"} />
                </CardTitle>

                <CardText>{_id}</CardText>
                <Table className="Tables">

                  <Thead>
                    <Tr>
                      <Th>{language[localStorage.getItem('language')].tuote}</Th>
                      <Th>{language[localStorage.getItem('language')].kerataan}</Th>
                      <Th>{language[localStorage.getItem('language')].kerayspiste}</Th>
                      <Th>{language[localStorage.getItem('language')].lisatietoa}</Th>
                    </Tr>
                  </Thead>

                  {
                    loadingUpdt ?
                      <div className="tableLoaders">
                        <Loader
                          css={override}
                          height={140}
                          width={16}
                          color={"#123abc"}
                          loading={loadingUpdt} />
                      </div>
                      : undefined
                  }

                  {products.map(product =>
                    <Tbody key={"dialog" + _id}>
                      <Tr>
                        <Td>
                          <div className="inputlabelU">
                            <MyAutosuggest items={this.props.items} id={`kukka/${product._id}`} placeholder={product.kukka} sendClass={"AutoCompleteInput"} getDivClass={"AutoCompleteText"} />
                          </div>
                        </Td>

                        <Td>
                          <Input type="number"
                            name="toimi"
                            id={`toimi/${product._id}`}
                            onChange={this.handleChange}
                            className="inputlabelU"
                            placeholder={product.toimi}>
                          </Input>
                        </Td>

                        <Td>
                          <Input type="select"
                            name="kerays"
                            id={`kerays/${product._id}`}
                            onChange={this.handleChange}
                            className="inputlabelU"
                            defaultValue={product.kerays}>
                            <option>Ryönä</option>
                            <option>Tuusjärvi</option>
                          </Input>
                        </Td>

                        <Td>
                          <Input type="text"
                            name="lisatieto"
                            id={`lisatieto/${product._id}`}
                            onChange={this.handleChange}
                            className="inputlabelA"
                            placeholder={product.lisatieto}>
                          </Input>
                          <Button className="delProduct" name="poista_kukka" color="danger" onClick={() => this.deleteData(product, _id)}>X</Button>
                        </Td>
                      </Tr>
                    </Tbody>
                  )}
                </Table>
                {sessionStorage.getItem('siteName') === "Kerättävät" ?
                  <div>
                    <Button name="lisaa_kukka" className="addFlower" onClick={() => this.addFlowers(_id, products)}>{language[localStorage.getItem('language')].addflower}</Button>
                    <Input type="number"
                      name="addFlowersValue"
                      className="addFlowerInput"
                      max={10}
                      min={1}
                      value={this.state.addFlowersValue}
                      onChange={this.handleChange}>
                    </Input>
                  </div> : undefined}
                <div className="taulukkoDivider"></div>
                <Button name="paivita_taulukon_tiedot" color="success" onClick={() => this.putFlowersIData(products, _id, kauppa, alisatieto, toimituspvm, date, orderLisatieto)}>{language[localStorage.getItem('language')].paivita}</Button>
              </Card>
            </Dialog>
          </div>
        </div >
      </ErrorBoundary>
    )
  }
}

export default PeopleCard;
